// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyDVAeJupycH-rkPg1G_Gcdjv9jt30UgcQU',
    authDomain: 'testauth-f9afd.firebaseapp.com',
    projectId: 'testauth-f9afd',
    storageBucket: 'testauth-f9afd.appspot.com',
    messagingSenderId: '1049346703407',
    appId: '1:1049346703407:web:457b217bf1dab3637606da'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);

const googleProvider = new GoogleAuthProvider();
const microsoftProvider = new OAuthProvider('microsoft.com');

export { app, auth, storage, googleProvider, microsoftProvider };