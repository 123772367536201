import React from 'react';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { auth, storage } from '../bin/firebase';
import { useNavigate, Link } from 'react-router-dom';
import { ref,uploadBytes, getDownloadURL } from 'firebase/storage';
import { useState } from 'react';
import Spinner from '../components/loading-spinner/loading-spinner';
import { useAuthState } from 'react-firebase-hooks/auth';
import Error from './error';

function Register() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [imageURL, setImageURL] = useState('');
    const [user] = useAuthState(auth);
    function regiserToFirebase(e) {
        e.preventDefault();
        setLoading(true);
        let pic = e.target.photo.files[0];
        let email = e.target.email.value;
        let username = e.target.username.value;
        let password = e.target.password.value;
        let password2 = e.target.password2.value;

        if (password === password2) {
            createUserWithEmailAndPassword(auth, email, password)
                .then(async (credential) => {
                    let user = credential.user;
                    let picLocation = ref(storage, `${user.uid}/profilePic.png`);
                    await uploadBytes(picLocation,pic);
                    updateProfile(user, {
                        displayName: username,
                        photoURL: await getDownloadURL(picLocation)
                    })
                        .then(() => {
                            navigate('/');
                        });
                });
        }
    }
    
    return (
        <>
            {loading ? <Spinner /> : user ? <Error /> : <div className='accountPage'>
                <form className='accountDiv' onSubmit={regiserToFirebase}>
                    <div>
                        <label htmlFor="photo" className="profilePicLabel">
                            <p>Upload Photo</p>
                            <img src={imageURL? imageURL : 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/HD_transparent_picture.png/1024px-HD_transparent_picture.png'} alt="" />
                        </label>
                        <input type="file" accept='image/*' name="photo" id="photo" hidden='true' onChange={(e) => {
                            setImageURL(window.URL.createObjectURL(e.target.files[0]));
                        }}/>
                    </div>
                    <div>
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" id="email" />
                    </div>
                    <div>
                        <label htmlFor="username">Username</label>
                        <input type="text" name="username" id="username" />
                    </div>
                    <div className='passwordConfirm'>
                        <div>
                            <label htmlFor="password">Password</label>
                            <input type="password" name="password" id="password" />
                        </div>
                        <div>
                            <label htmlFor="password">Confirm Password</label>
                            <input type="password" name="password2" id="password2" />
                        </div>
                    </div>
                    <button type="submit">Sign Up</button>
                </form>
                <Link to='/'>Back</Link>
            </div>}
        </>
    );
}

export default Register;