import React from 'react';
import { Link } from 'react-router-dom';

function Error() {
    
    return (
        <div className='errorPage'>
            <h1>404 :(</h1>
            <h2>This Page Does not Exist...</h2>
            <Link to='/'>Go Home</Link>
        </div>
    );
}

export default Error;