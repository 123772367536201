import React from 'react';
import styles from './header.module.css';
import PropTypes from 'prop-types';

function Header({ title = 'Insert Page Title', subtitle = 'Insert Page Subtitle' }) {
    return (
        <header className={styles.header}>
            <h2 className={styles.h2}>{subtitle}</h2>
            <h1 className={styles.h1}>{title}</h1>
        </header>
    );
}

Header.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string
};

export default Header;