import React, { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, googleProvider, microsoftProvider, storage } from '../bin/firebase';
import { signInWithPopup, signOut, deleteUser, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { deleteObject, ref } from 'firebase/storage';
import Spinner from '../components/loading-spinner/loading-spinner';


function Account() {
    const [user] = useAuthState(auth);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    function loginWithGoogle() {
        signInWithPopup(auth, googleProvider)
            .then(() => {
                navigate('/');
            })
            .catch(() => {
                
            });
    }
    function loginWithMicrosoft() {
        signInWithPopup(auth, microsoftProvider)
            .then(() => {
                navigate('/');
            })
            .catch(() => {

            });
    }

    function logout() {
        setLoading(true);
        signOut(auth)
            .then(() => {
                navigate('/');
            })
            .catch((e) => {
                setLoading(false);
                alert(e);
            });
    }

    function deleteAccount() {
        let check = window.confirm('Are you sure you want to delete your account?');

        if (check) {
            setLoading(true);
            let storageRef = ref(storage, `${user.uid}/profilePic.png`);
            deleteObject(storageRef);
            deleteUser(user)
                .then(() => {
                    navigate('/');
                })
                .catch((e) => {
                    setLoading(false);
                    alert(e);
                });
        }
    }

    function formSubmit(e) {
        e.preventDefault();
        setLoading(true);
        let email = e.target.email.value;
        let password = e.target.password.value;
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                navigate('/');
            })
            .catch((e) => {
                setLoading(false);
                alert(e);
            });
    }

    return (
        <>
            {loading? <Spinner/> : <div className='accountPage'>
                {user ? (
                    <>
                        <img loading='lazy' src={user.photoURL? user.photoURL.replace('96','256') : 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/768px-Windows_10_Default_Profile_Picture.svg.png'} alt="Account Photo" />
                        <h2>{ user.displayName}</h2>
                        <h3>{ user.email}</h3>
                        <button onClick={logout}>Log Out</button>
                        <button onClick={deleteAccount} className='accountDelete'>Delete Account</button>
                    </>
                ) : (
                    <div className='accountDiv'>
                        <button onClick={loginWithGoogle}>Login With Google</button>
                        <button onClick={loginWithMicrosoft}>Login With Microsoft</button>
                        <form onSubmit={formSubmit}>
                            <div>
                                <label htmlFor="email">Email</label>
                                <input type="text" name="email" id="email" />
                            </div>
                            <div>
                                <label htmlFor="password">Password</label>
                                <input type="password" name="password" id="password" />
                                <Link className='forgotPass' to='/'>Forgot Your Password?</Link>
                            </div>
                            <button type="submit">Login</button>
                        </form>
                    </div>
                )}
                <div className='backAndReg'>
                    <Link to='/'>Back</Link>
                    {user? null :<Link to='/register'>Or Sign Up...</Link>}
                </div>
            </div>}
        </>
    );
}

export default Account;