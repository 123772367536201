import React from 'react';
import Header from '../components/header/header';
import Nav from '../components/nav/nav';
import navStyles from '../components/nav/nav.module.css';


function Index() {
    return (
        <div className={navStyles.div}>
            <Nav />
            <Header title='Website' subtitle='This is a Test Website'/>
            <div>Page: /</div>
        </div>
    );
}

export default Index;