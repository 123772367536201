import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import styles from './nav.module.css';
import {auth} from '../../bin/firebase';
import {useAuthState} from 'react-firebase-hooks/auth';

function Nav() {
    const [user] = useAuthState(auth);
    const [showNav, setShowNav] = useState(false);

    function toggleNav() {
        setShowNav(!showNav);
    }

    return (
        <>
            <button className={showNav? styles.toggleSelected : styles.toggle} onClick={toggleNav}><span className="material-symbols-outlined">
                {showNav? 'close' : 'menu'}
            </span></button>
            <nav className={showNav? styles.nav : styles.hidden}>
                <h2 className={styles.title}>Website</h2>
                <Link className={styles.link} to='/'>Home</Link>
                <Link className={styles.link} to='/about'>About</Link>
                <Link className={styles.link} to='/contact'>Contact Us</Link>
                <Link className={`${styles.link} ${styles.account}`} to='/account'>{user ? <><img loading='lazy' className={styles.accountPhoto} src={user.photoURL? user.photoURL : 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/768px-Windows_10_Default_Profile_Picture.svg.png'} alt="Account Photo" />{user.displayName}</>: 'Login'}</Link>
            </nav>
        </>
    );
}

export default Nav;