import React from 'react';
import Header from '../components/header/header';
import Nav from '../components/nav/nav';
import navStyles from '../components/nav/nav.module.css';

function Contact() {
    return (
        <div className={navStyles.div}>
            <Nav />
            <Header title='Contact Us' subtitle='Where to find Us...'/>
            <div>Page: /contact</div>
        </div>
    );
}

export default Contact;