import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { auth } from './bin/firebase';
import { onAuthStateChanged } from 'firebase/auth';

import Spinner from './components/loading-spinner/loading-spinner';
import Error from './pages/error';
import Index from './pages';
import About from './pages/about';
import Contact from './pages/contact';
import Account from './pages/account';
import Register from './pages/register';


function App() {
    const [loading, setLoading] = useState(true);

    onAuthStateChanged(auth, () => {
        setLoading(false);
    });
    
    return (
        <>
            <BrowserRouter>
                <Routes>
                    {loading ? (
                        <Route path='*' element={<Spinner />} />
                    ) : (
                        <>
                            <Route path='*' element={<Error />} />
                            <Route path='/' element={<Index />} />
                            <Route path='/about' element={<About />} />
                            <Route path='/contact' element={<Contact />} />
                            <Route path='/account' element={<Account />} />
                            <Route path='/register' element={<Register />} />
                        </>
                    )}
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;