import React from 'react';
import Header from '../components/header/header';
import Nav from '../components/nav/nav';
import navStyles from '../components/nav/nav.module.css';

function About() {
    return (
        <div className={navStyles.div}>
            <Nav />
            <Header title='About Us' subtitle='Who we Are...'/>
            <div>Page: /about</div>
        </div>
    );
}

export default About;